import { environment } from "src/environments/environment";
import axios from "axios";

const Axios = axios.create({
  baseURL: environment.baseURL
});

Axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token)
    config.headers.Authorization = token;
  return config;
})

export const LoginApi = (data) => {
  return Axios.post(`/users/login`, { data });
};

export const SignupAPi = (data) => {
  return Axios.post(`/users/signup`, { data });
};

export const ForgotPasswordApi = (data) => {
  return Axios.post(`/users/forgotPassword`, { data });
};

export const VerifyOtpApi = (data) => {
  return Axios.post(`/users/verifyOtp`, { data });
};

export const ResetPasswordApi = (data) => {
  return Axios.post(`/users/resetPassword`, { data });
};


// Patients api
export const GetPatientsSummaryAuthHistory = (page, pageSize, columnFilter, searchFilterObj, sortFilter) => {
  return Axios.post(`/patients/summary_auth_history`,
    {}, { params: { page, pageSize, columnFilter, searchFilterObj, sortFilter } });
};

export const GetPatientsHistory = (page, pageSize, searchFilterObj, sortFilter) => {
  return Axios.post(`/patients/patients_history`,
    {}, { params: { page, pageSize, searchFilterObj, sortFilter } });
};

export const GetPatientsAppealHistory = (page, pageSize, columnFilter, searchFilterObj, sortFilter) => {
  return Axios.post(`/patients/appeal_history`,
    {}, { params: { page, pageSize, columnFilter, searchFilterObj, sortFilter } });
};

export const UpdateAuthSummary = (data) => {
  return Axios.post(`/patients/update_auth_summary`, { data })
}

export const DeleteAuthSummary = (data) => {
  return Axios.post(`/patients/deleteAuth`, {
    data,
  });
};

export const SavePatientsData = (data) => {
  return Axios.post(`/patients/save_patient_details`, {
    data,
  });
};

export const getPatientsData = (page, pageSize, columnFilter, searchFilterObj, sortFilter) => {
  return Axios.post(`/patients/get_patient_details`,
    {}, { params: { page, pageSize, columnFilter, searchFilterObj, sortFilter } });
};

//TODO: This is only getting one patient. This needs to change so it doesn't cause confusion in future
export const GetPatientsDataByID = (data) => {
  return Axios.post(`/patients/get_patients_byid/${data.id}`, {
    data,
  });
};

export const UpdatePatientsData = (data) => {
  return Axios.post(`/patients/edit_patient_details`, {
    data,
  });
};

export const DeletePatient = (data) => {
  return Axios.post(`/patients/delete`, {
    data,
  });
};

// email trigger api

export const SendTwilioEmail = (data) => {
  return Axios.post(`/send_email`, {
    data,
  });
};

// messages trigger api

export const SendTwilioSms = (data) => {
  return Axios.post(`/send_msg`, {
    data,
  });
};

// User api

export const UsersData = (data) => {
  return Axios.post(`/users/get_all_users`, {
    data,
  });
};

export const SSOLoginApi = (data) => {
  return Axios.post(`/users/sso_login`, {
    data,
  });
}

export const DeleteUser = (data) => {
  return Axios.post(`/users/delete`, {
    data,
  });
};

export const GetUSerByDataByID = (data) => {
  return Axios.post(`/users/get_user_by_id/${data.uuid}`, {
    data,
  });
};

export const UpdateUSerData = (data) => {
  return Axios.post(`/users/update_user_data`, {
    data,
  });
};

// Patients Logs api

export const PatientsFromLogs = (data, page, pageSize, columnFilter, searchFilterObj, sortFilter) => {
  return Axios.post(`/patients/logs/${data.patient_id}`, {
    data,
  }, { params: { page, pageSize, columnFilter, searchFilterObj, sortFilter } });
};

export const AllPatientsLogs = (page, pageSize, columnFilter, searchFilterObj, sortFilter) => {
  return Axios.post(`/patients/logs/all`, {},
    { params: { page, pageSize, columnFilter, searchFilterObj, sortFilter } });
};
//Calling API
export const LoginRingCentral = (data) => {
  return Axios.post(`/patients/calls/login`,
    { data });
};

//Calling API
export const CallPatient = (phoneNumber, email) => {
  return Axios.post(`/patients/calls/ring-out`,
    { data: { phoneToCall: phoneNumber, email: email } });
};


// Calender api

export const getClinicalDirectorsFromLocation = (data) => {
  return Axios.post(`/users/clinical_directors`, {
    data,
  });
};

// triggers notifications api call for Slack & MS Teams
export const sendIntakeAuthsMessage = (data, notification) => {
  return Axios.post(`/triggers/intakeAuthsMessage`, {
    data,
  }, { params: { notification } });
};

export const sendInsuranceVerificationMessage = (data, todoListName) => {
  return Axios.post(`/triggers/insuranceVerificationMessage`, {
    data,
  }, { params: { todoListName } });
};

export const sendAssessmentVisitMessage = (data, notification) => {
  return Axios.post(`/triggers/assessmentVisitMessage`, {
    data
  }, { params: { notification } });
};

export const scheduleAssessmentVisitMessage = (data, notification) => {
  return Axios.post(`/triggers/scheduleAssessmentVisitMessage`, {
    data
  }, { params: { notification } });
};

export const sendClinicVisitMessage = (data, notification) => {
  return Axios.post(`/triggers/clinicVisitMessage`, {
    data,
  }, { params: { notification } });
};

// make delegate and events logs
export const sendEventLog = (userData, eventData) => {
  return Axios.post(`/events/log`, {
    userData, eventData
  });
};

export const createMailEvent = (data, attendee) => {
  return Axios.post(`/mailgun/send_event`, {
    data, attendee
  });
};


export const CheckEligibity = (data, token) => {
  return Axios.post(`/healthcare/hcCheckEligibity`, {
    data, config: {
      'Authorization': token
    },
  });
};

export const generateToken = () => {
  return Axios.post(`healthcare/generateToken`);
}

export const getGithubCards = () => {
  return Axios.post(`github/getCards`);
}

export const createGithubCards = (data) => {
  return Axios.post(`github/createCards`, { data });
}

export const getGithubIssues = (data) => {
  return Axios.post(`github/getIssues`, { data });
}

export const getGithubSearchIssues = (data) => {
  return Axios.post(`github/searchIssues`, { data });
}

export const createGithubIssues = (data) => {
  return Axios.post(`github/createIssue`, { data });
}

// user Notificaitons api
export const GetAllUserNotifications = () => {
  return Axios.post(`user_notification/get_user_notifications`);
}
export const GetCountNotifications = () => {
  return Axios.post(`user_notification/get_count_notifications`);
}

export const UpdateNotifications = () => {
  return Axios.post(`user_notification/update_notifications`);
}
export const UpdateNotification = (data) => {
  return Axios.post(`user_notification/update_notification`, {
    data,
  });
};


// location api
export const GetLocations = () => {
  return Axios.post(`locations/getLocations`);
}

export const GetAllLocations = () => {
  return Axios.post(`locations/getAllLocations`);
}

export const SaveLocation = (data) => {
  return Axios.post(`locations/saveLocation`, {
    data,
  });
}

export const UpdateLocation = (data) => {
  return Axios.post(`locations/updateLocation`, {
    data,
  });
}

// pending status api
export const GetPendingStatuses = () => {
  return Axios.post(`pendingStatus/getPendingStatuses`);
}

export const GetAllPendingStatuses = () => {
  return Axios.post(`pendingStatus/getAllPendingStatuses`);
}

export const SavePendingStatus = (data) => {
  return Axios.post(`pendingStatus/savePendingStatus`, {
    data,
  });
}

export const updatePendingStatus = (data) => {
  return Axios.post(`pendingStatus/updatePendingStatus`, {
    data,
  });
}

// payors api
export const GetPayors = () => {
  return Axios.post(`payors/getPayors`);
}

export const SavePayor = (data) => {
  return Axios.post(`payors/savePayor`, {
    data,
  });
}

export const updatePayor = (data) => {
  return Axios.post(`payors/updatePayor`, {
    data,
  });
}

// resources api
export const GetResources = () => {
  return Axios.post(`resources/getResources`);
}
export const DeleteResource = (fileName) => {
  return Axios.delete(`resources/deleteFile/${fileName}`)
}

export const DownloadResource = (fileName) => {
  return Axios.post(`resources/download/${fileName}`, {
    responseType: 'blob'
  });
}

export const UploadResources = (data) => {
  const formData = new FormData();
  formData.append('file', data.file);
  formData.append('name', data.name);

  const userEmail = localStorage.getItem('userEmail');
  formData.append('email', userEmail)
  return Axios.post('/resources/uploadResources', formData, {
    headers: {
      'Authorization': 'Bearer your-jwt-token', // replace with your actual token
      'Content-Type': 'multipart/form-data'
    }
  });

  // return Axios.post(`payors/uploadResources`, {
  //   data,
  // });
}


